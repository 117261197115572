
// 体验卡页面
import { defineComponent ,ref,onActivated ,reactive, toRefs, onBeforeMount} from 'vue';
import {  getExperienceList } from '@/api/personal/experienceCard';
import { message } from 'ant-design-vue';
import breadcrumb from '@/components/breadcrumb/breadcrumb.vue';
export default defineComponent({
    name:'entityCard',
    components:{
        breadcrumb
    },
    setup(){
        // 面包屑
        const breadData = ref<Array<object>>([
            {title:'首页',path:'#/'},
            {title:'个人',path:'/index'},
            {title:'我的实体卡',path:'#/'},
        ])
        const query = reactive({
            card_number:'',
            status:'',
        })
        // table
        const columns = [
            { title: '卡号',  dataIndex: 'card_number', key: 'card_number', fixed: 'left' },
            { title: '使用会员', dataIndex: 'nickname', key: 'nickname'},
            { title: '使用时间', dataIndex: 'use_time', key: 'use_time',},
            { title: '时长', dataIndex: 'duration_name', key: 'duration_name'},
            { title: '状态', dataIndex: 'type', key: 'type' },
            {title: '激活码',dataIndex: 'activate_code',key: 'activate_code',},
            { title: '创建时间', dataIndex: 'create_time', key: 'create_time', },
        ];
        const dataSource = ref<Array<any>>([]);
        const pagination = reactive({
            page:1,
            per_page:10,
            total:0,
        })

        onBeforeMount(()=>{
            let role_id = localStorage.getItem('userInfo')
            role_id = JSON.parse(role_id as string)
            if((role_id as any).role_id == 5){
                breadData.value.splice(2,1)
            }
            getListData()
        })

         //列表数据
        const getListData = (isPage?:string):void =>{
            if(isPage == 'page'){
                pagination.page = 1;
            }
            let data = {
                page:pagination.page,
                per_page:pagination.per_page,
                card_number:query.card_number,
                type:1,
                status:query.status,
            }
            if(!/^[\da-z]+$/i.test(query.card_number) && query.card_number != ''){
              message.error('只能输入数字或英文')
              return
            }
            getExperienceList(data).then((res:any)=>{
                if(res.code == 200){
                    pagination.total = res.total;
                    pagination.page = res.page;
                    dataSource.value = res.data;
                }else{
                    message.error(res.msg)
                }
            })
        }

        // 分页切换
        const tableChange = (page:any):void =>{
            pagination.page = page.current;
            pagination.per_page = page.pageSize;
            getListData()
        }

        const selectChange = ():void =>{
            getListData()
        }

        return {
            columns,
            dataSource,
            ...toRefs(query),
            ...toRefs(pagination),
            getListData,
            tableChange,
            selectChange,
            breadData
        }
    }
})
